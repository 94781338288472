import { Selector } from '@ngxs/store';

import {
  OverviewAuditCalendarStateModel,
  OverviewSharedState,
} from '../overview-shared.state';

export class OverviewSharedSelectors {
  @Selector([OverviewSharedState])
  static getSelectedDate(
    state: OverviewAuditCalendarStateModel,
  ): Date | undefined {
    return state.selectedDate;
  }
}
