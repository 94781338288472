import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { OverviewUpcomingSetSelectedDate } from '../actions';
import { OverviewSharedState } from '../overview-shared.state';

@Injectable({ providedIn: 'root' })
export class OverviewSharedStoreService {
  constructor(private store: Store) {}

  get overviewUpcomingAuditSelectedDate(): Signal<Date | undefined> {
    return this.store.selectSignal(OverviewSharedState.getSelectedDate);
  }

  @Dispatch()
  setSelectedDate = (date: Date | undefined) =>
    new OverviewUpcomingSetSelectedDate(date);
}
