import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { OverviewUpcomingSetSelectedDate } from './actions';

export interface OverviewAuditCalendarStateModel {
  selectedDate: Date | undefined;
}

const defaultState: OverviewAuditCalendarStateModel = {
  selectedDate: undefined,
};

@State<OverviewAuditCalendarStateModel>({
  name: 'overviewAuditCalendar',
  defaults: defaultState,
})
@Injectable()
export class OverviewSharedState {
  @Selector()
  static getSelectedDate(
    state: OverviewAuditCalendarStateModel,
  ): Date | undefined {
    return state?.selectedDate;
  }

  @Action(OverviewUpcomingSetSelectedDate)
  setSelectedDate(
    ctx: StateContext<OverviewAuditCalendarStateModel>,
    action: OverviewUpcomingSetSelectedDate,
  ) {
    ctx.patchState({ selectedDate: action.date });
    ctx.dispatch(new Navigate(['/schedule']));
  }
}
